.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2;
}

.full {
  width: 100%;
}

.group {
  display: table;
  min-width: 40vw;
}

.form {
  width: 60%;
  min-width: 715px;
  max-height: 98vh;
}

.half {
  width: 40vw;
  /* max-width: 30vw; */
  display: table-cell;

  min-height: 40vh;
  max-height: 80vh;
}

.right {
  /* float: right; */
  /* align-self: right; */
  align-content: right;
}

.bottomPadding {
  margin-bottom: 10px;
}

.center {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
