.modalContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2;
}

.form {
  width: 80%;
}

.right {
  /* float: right; */
  /* align-self: right; */
  align-content: right;
}

.bottomPadding {
  margin-bottom: 10px;
}

.center {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
